/* font-family: "HelveticaCE";
font-family: "HelveticaCEBold";
font-family: "AmandaBold";
font-family: "Amanda"; */

.header_logo {
  font-family: "decima_mono_cyrregular";
  font-size: 10px;
  text-decoration: none;
  color: #221f1f;
  margin: 0px;
  padding: 0px;
}
.header {
  padding: 33px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  position: fixed;
  width: 100%;
  z-index: 34343434;
}

.miniLinks {
  margin: 0px;
  padding: 0px;
  margin-right: 3px;
}

.miniLinks li {
  display: inline;
  margin-left: 11px;
}

.miniLinks li a {
  color: #000;
  font-size: 9.5px;
  letter-spacing: -0.5px;
  padding: 2px 3px 2px 3px;

  font-family: "decima_mono_cyrregular";

  *font-weight: bolder;
}
.coloritoA:hover {
  color: #75ff28 !important;
}
.coloritoB:hover {
  color: #d19cff !important;
}
.coloritoC:hover {
  color: #ddd200 !important;
}
.miniLinks li a:hover {
  color: black !important;
  background: #9f7fcf;
  padding: 2px 3px 2px 3px;
}

/* .miniLinks li a:hover {
  color: white;

  text-decoration: underline;
} */
.ho:hover {
  color: white;
}
strong {
  font-family: "decima_mono_cyrregular";
  font-size: 1.1rem;
  letter-spacing: 0px;
}

video {
  widows: 925px;
}

.logo {
  display: block;
  width: 46px;
  height: 20.5px;
  background: url("../statics/sprite-logo.svg") left top;
}

.logo:hover {
  animation: play 0.5s steps(5) infinite;
}

.logoani {
  position: absolute;
  top: 33px;
  left: 33px;
  display: block;
  width: 46px;
  height: 20.5px;
  background: url("../statics/sprite-logo.svg") left top;

  animation: play 0.5s steps(5) infinite;
}

@keyframes play {
  100% {
    background-position: -230px;
  }
}

.Ying {
  width: 12px;
}
.log {
  letter-spacing: -0.5px;
  background: #9f7fcf;
  padding: 1px 3px 0px 3px;
  margin-left: -3px;
}
