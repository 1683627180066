.loader-wraper {
  background: #f3f3f1;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000000;
  top: 0px;
}
.loader-img {
  width: 100px;
}

@media only screen and (max-width: 600px) {
  .logoani {
    left: inherit !important;
    top: 22px !important;
    margin-left: 5px;
  }
}
