.PicPinteres img {
  width: 100%;
}

.PicPinteres-tags {
  text-align: center;
  margin-top: -3px;
}
.tagItemDetails--nombre {
  margin-top: 23px;
  text-align: center;
}

.topc {
  margin-top: 23px !important;
}
