/* @font-face {
  font-family: "HelveticaCE";
  src: url("../fonts/HelveticaCE-Regular.woff2") format("woff2"),
    url("../fonts/HelveticaCE-Regular.woff") format("woff"),
    url("../fonts/HelveticaCE-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Adobe Garamond Pro";
  src: url("../fonts/AGaramondPro-Italic.woff2") format("woff2"),
    url("../fonts/AGaramondPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "HelveticaCEBold";
  src: url("../fonts/HelveticaCE-Bold.woff2") format("woff2"),
    url("../fonts/HelveticaCE-Bold.woff") format("woff"),
    url("../fonts/HelveticaCE-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "AmandaBold";
  src: url("../fonts/Amanda-Bold.woff2") format("woff2"),
    url("../fonts/Amanda-Bold.woff") format("woff"),
    url("../fonts/Amanda-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Amanda";
  src: url("../fonts/Amanda.woff2") format("woff2"),
    url("../fonts/Amanda.woff") format("woff"),
    url("../fonts/Amanda.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("../fonts/HelveticaNeueLT-BoldExt.woff2") format("woff2"),
    url("../fonts/HelveticaNeueLT-BoldExt.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../fonts/HelveticaNeueLT-Extended.woff2") format("woff2"),
    url("../fonts/HelveticaNeueLT-Extended.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "decima_mono_cyrregular";
  src: url("../fonts/decimamonocyr-webfont.woff2") format("woff2"),
    url("../fonts/decimamonocyr-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

a {
  text-decoration: none;
}
.nuevo {
  position: absolute;
  top: -20px;
  width: 40px;
  right: 15px;
}

html {
  font-size: 10px;
  /* -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%; */
}
body {
  font-family: "decima_mono_cyrregular";
  margin: 0px;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  color: #000;
  font-size: 9.5px;
  background: #efefef;
}

.fixyin {
  margin-bottom: 20px !important;
}
video {
  width: 100%;
}
.tagItem a {
  color: #000;
}
a .tagItemDetails--nombre {
  color: #000 !important;
}
.bodyx {
  background: #f3f3f1;
  transition: 0.1s;
}

.works {
  list-style: none;
  color: #000;
  margin: 0px;
  padding: 0px;
  padding-left: 11px;
  position: fixed;
  top: 70px;
  left: 20px;
  z-index: 343;
}
.work_name {
  font-family: "decima_mono_cyrregular";
  line-height: 19px;
  padding: 1px;
  color: #000;
  font-size: 9.5px;
  display: inline;
  letter-spacing: -0.5px;
  padding: 2px 3px 2px 3px;
}

.work_name:hover,
.work_tag:hover {
  color: black;
  background: #9f7fcf;
  padding: 2px 3px 2px 3px;
}
a:hover {
  text-decoration: none;
}
.work_tag {
  color: #000;
  letter-spacing: 0.3px;
  margin-right: 4px;
}
/* .work_tag:hover {
} */
.containerx {
  display: flex;
  padding-top: 83px;
}

section {
  width: 925px;
  margin-top: 18px;

  margin-right: 33px;
}

.fiximg {
  margin-bottom: 20px !important;
}
a {
  color: #000 !important;
}

.work {
  padding-top: 0px;
  display: flex;
  *justify-content: end;
  align-items: start;
  font-size: 10px;
}

.info {
  margin-top: -6px;
  letter-spacing: -0.5px;
  line-height: 15px;
  text-align: justify;
  padding-right: 2px;
}
.infoOtro {
  *margin-top: -1px;
}
.pre {
  margin-top: -30px;
}
.info p {
  line-height: 15px;
  letter-spacing: -0.5px;
}

footer {
  padding-left: 33px;
  padding-bottom: 18px;
  position: fixed;
  bottom: 0px;
}

.contentImg,
.contentVideo {
  margin-top: 16px;
}

.contentImg img {
  width: 100%;
  object-fit: cover;
}
.col-md-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.container-fluid {
  padding-left: 22px;
  padding-right: 22px;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.pepe {
  object-fit: cover;
  position: absolute;
  top: calc(100% - 210px);
}

.tagItem {
  width: 100%;
  height: 190px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-size: 2rem;
  -webkit-transition: all 0.45s;
  transition: all 0.45s;
  -webkit-transform-origin: center left;
  transform-origin: center left;
  margin-bottom: 10px;
}
.tagItem img {
  object-fit: cover;
  -o-object-fit: cover;
  width: 100%;
}

.PicTagPic {
  min-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.activo {
  background: #9f7fcf;
  //display: -webkit-inline-box;
}
.tagItemDetails {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  font-size: 2rem;
  flex-direction: column;
  left: 0;
  opacity: 1;
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
  padding: 20px 20px 20px 25px;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}
.tagItem:hover .tagItemDetails {
  opacity: 1;
}
.tagItemDetails--nombre {
  font-family: "decima_mono_cyrregular";
  font-style: italic;

  font-size: 2.2rem;
  margin: 0px;
}
.wrapper-tag {
  width: 100%;
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;

  grid-gap: 13px;
  margin-top: 6px;

  margin-right: 33px;
  margin-left: 40px;
}
.row.no-gutter [class*="col-"]:only-child,
.row.no-gutter [class*="col-"]:only-child {
  padding-right: 0;
  padding-left: 0;
}
.volver {
  margin-top: 1px;
  margin-left: 4px;
  margin-bottom: 0px;
}

/* .works {
  transition: 0.2s;
} */
.worksContent {
  margin: 0px;
  list-style: none;
  padding: 0px;
  *position: fixed;
  margin-top: 20px;
  *height: 82vh;
  overflow: scroll;
  padding-bottom: 20px;
}
.linki {
  color: black !important;
}
.linki:hover {
  color: #9f7fcf !important;
}

strong {
  font-weight: bold;
  font-size: 1.1rem;
}
/* AJustes locos */
.ajustetag {
  margin-top: 60px;
}
.switchMenu-ajuste {
  margin-left: 12px;
}
/* AJustes locos */

.Yin:hover {
  animation: rotate 3s infinite;
  animation-name: rotate;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}
.btnmobile {
  width: 20px;
}
#volver:hover {
  color: white;
}
.vitag {
  min-height: 200px;
}
.col-3 {
  padding-right: 10px;
  padding-left: 10px;
}
.centradino {
  display: flex;
}
video {
  background-image: url("../statics/sprite-logo.svg") left top;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: center;
}

/* .row {
  width: 100%;
  padding: 10px 0px 10px 50px;
} */
.container-fluid {
  padding-top: 80px;
}
@keyframes rotate {
  0%,
  50% {
    transform: rotate(90deg);
  }
  50%,
  100% {
    transform: rotate(180eg);
  }
}
.on {
  display: none;
}

.tags-bloque {
  display: inline-block;
  width: 124px;
  margin-left: 10px;
  line-height: 11px;
  margin-top: -1px;
}
.tags-bloque span {
  display: inline-block;
}
.logMob {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .col-lg-6 {
    max-width: 100% !important;
    flex: none;
  }

  .topc {
    margin-top: 18px !important;
  }
  .col-lg-6 {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .coloritoA:hover {
    color: #000 !important;
  }
  .coloritoB:hover {
    color: #000 !important;
  }
  .coloritoC:hover {
    color: #000 !important;
  }
  .logMob {
    display: flex;
    position: fixed;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .my-masonry-grid_column {
    padding-left: 22px !important;
  }

  .my-masonry-grid {
    margin-right: 6px !important;
  }
  .ajustetag {
    margin-top: 0px;
  }
  .ajustador {
    margin-top: 0px !important;
  }
  .tags-bloque {
    width: 100%;
    display: block;
  }
  .work {
    display: block;
  }
  .fixyin img {
    height: 100%;
  }
  .containerx {
    display: flex;
    padding-top: 60px;
  }
  .fixtop {
    margin-top: 15px;
  }

  .fixtop2 {
    margin-top: 20px;
  }

  .tagItem img {
    width: 100%;
  }
  section {
    margin: 15px;
  }
  .on {
    display: block;
  }
  .off {
    display: none !important;
  }
  .header {
    padding: 15px;
    padding-top: 22px;
    z-index: 34353232324;
  }
  .works {
    width: 100%;
    padding-left: 0px;
    text-align: center;
  }
  .wrapper-tag {
    display: block;
    margin: 15px;
  }
  .pepe {
    top: 0;
    left: 0;
    width: 100%;
  }

  .tagItem {
    height: 200px;
    margin-top: 0px;
    margin-bottom: 14px;
  }
  video {
    width: 100% !important;

    object-fit: fill !important;
  }
  .miniLinks {
    z-index: 234444444 !important;
  }

  .miniLinks li {
    margin-left: 0px;
  }
  .coloritoA {
    display: inline;
    margin-right: 6px;
  }
  .coloritoB {
    margin-right: 10px;
  }
  footer {
    padding-left: 15px;
    right: 16px;
  }
  .logo {
    margin-left: -30px;
  }
  .work_name {
    font-size: 2.4rem;
  }
  .info {
    text-align: justify;
    margin-top: 0px;
  }
  .infoAjuste {
    padding-left: 100px;
    padding-right: 100px;
  }
  footer {
    padding-bottom: 13px;
  }
  .contentImg,
  .contentVideo {
    margin-top: 11px;
  }

  .contentImg:first-child {
    margin-top: 100px !important;
  }

  .container-fluid {
    padding-left: 8px;
    padding-right: 8px;
  }
  .videoHome {
    width: 100% !important;
    height: auto !important;
  }
}
.ajustador {
  margin-top: 8px;
}
.switchMenu {
  margin-bottom: 15px;
  margin-top: 15px;
  *position: fixed;
  padding-left: 3px;
}
.switchMenu a {
  margin-right: 10px;
  padding-bottom: 3px;
}

.switchMenu a:hover {
  border-bottom: solid 0.1px black;
}
.act {
  border-bottom: solid 0.1px black;
  font-size: 9.5px;

  letter-spacing: -0.5px;
}
@media only screen and (min-device-width: 1200px) {
  section {
    width: 74%;
  }
  .pepe {
    height: 230px;
  }
  .tagItem img {
    height: 100%;
    object-fit: cover !important;
  }
}

/* Box-sizing reset: //w3bits.com/?p=3225 */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* The Masonry Container */
.masonry {
  margin: 1.5em auto;
  max-width: 768px;
  column-gap: 1.5em;
}

/* The Masonry Brick */
.item {
  background: #fff;
  padding: 1em;
  margin: 0 0 1.5em;
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .masonry {
    column-count: 4;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .masonry {
    column-count: 3;
  }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .masonry {
    column-count: 2;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;

  margin-left: -18px;
  width: auto;
  margin-right: 17px;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

@media only screen and (max-width: 2100px) and (min-width: 1600px) {
  .tags-bloque {
    width: 143px;
    margin-left: 9px;
    line-height: 15px;
    margin-top: 1px;
  }
  body {
    font-size: 0.9rem;
    line-height: 12px;
    letter-spacing: -0.8px;
  }
  .PicPinteres-tags {
    line-height: 40px;
  }
  .info p,
  .info {
    line-height: 15px;
  }
  .tagItemDetails--nombre {
    margin-top: 40px !important;
  }
}

.resalta {
  background: #9f7fcf;
  display: -webkit-inline-box;
  padding: 2px 3px 2px 3px;
}

.press a {
  color: #000 !important;
}
.press a:hover {
  background: #9f7fcf;
  text-decoration: none;
}

.mobiletit {
  text-align: center;
}
.instax {
  font-size: 14px;
}
.instax span:hover {
  color: #9f7fcf;
}

.log:hover {
  color: black !important;
  background: transparent;
}

.newajusta {
  padding-top: -3px;
}

.ho {
  letter-spacing: 0px;
}

.botoneraMobile {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.on {
  display: none;
}
@media only screen and (max-width: 600px) {
  .infomobile {
    text-align: center;
  }
  .header {
    padding: 5px 4px 5px 10px;
    padding-top: 17px;
  }
  .infoMobile {
    margin-right: -6px;
  }
  .homeMobile {
    margin-left: -7px;
  }
  .on {
    display: flex;
  }
  .videoHOMELOCO {
    margin-top: -35px;
    padding: 4px;
    height: 80vh;
  }
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.activoM {
  border-bottom: solid 1px black;
}
